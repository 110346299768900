<template>
    <!-- 底部Tab -->
    <div class="m-footer01">
        <div v-for="tab in tabList" :key="tab.text" class="footer-tab-item" :class="currentPage === tab.target  ? 'tab-active' : ''"  @click="handleTabToggle(tab.target)">
            <a>
                <div class="footer-tab-icon">
                    {{currentPage === tab.target}}
                    <img :src="currentPage === tab.target ? tab.activeImgSrc : tab.defaultImgSrc">
                </div>
                <div class="tab-txt">{{tab.text}}</div>
            </a>
        </div>
    </div>
</template>
<script>
export default ({
    name: 'commonFooter',
    props: ['currentTab'],
    data() {
        return {
            currentPage: '/index',
            tabList: [{
                target: '/index',
                text: '首頁',
                defaultImgSrc: require('@/assets/images/home.png'),
                activeImgSrc: require('@/assets/images/home.svg'),
            }, {
                target: '/mine',
                text: '我的',
                defaultImgSrc: require('@/assets/images/my.png'),
                activeImgSrc: require('@/assets/images/mine.svg'),
            }]
        }
    },
    mounted(){
        this.currentPage = this.currentTab
    },
    methods: {
        // handleTabToggle(tag) {
        //     this.$emit('handleTabToggle', tag)
        // }
        handleTabToggle(tag) {
            console.log('tag,', tag)
            if(`/tag` === this.currentPage) return;
            this.currentPage = tag
            // 路由跳转
            this.$router.push(this.currentPage)
        }
    }
})
</script>
<style lang="scss" scoped>
// 底部Tab
.m-footer01 {
    display: flex;
    justify-content: space-around;
    height: 49px;
    background: #fff;
    position: fixed;
    width: 100%;
    border-top: 1px solid #eee;
    left: 0;
    bottom: 0;
    text-align: center;
    .footer-tab-item a {
        display: block;
        padding-top: 4px;
        font-size: 0
    }
    .footer-tab-icon {
        position: relative;
        display: inline-block;
    }
    .footer-tab-item a img {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin: 0 auto;
    }
    .footer-tab-item .tab-txt {
        font-size: 11px;
        line-height: 13px;
        color: #999;
        margin-top: 2px;
    }
    .footer-tab-item.tab-active .tab-txt {
        color: #00C2C1;
    }
}
</style>
