<template>
    <div class="m-wrapper01 p-b-60">
<!--        <common-header>-->
<!--            台醫通-->
<!--        </common-header>-->
        <div class="container">
            <div class="m-modular01">
                <a class="modular-con01">
                    <div class="m-avatar01">
                        <img :src="avadarImgSrc" class="avatar01"  @click="linkGo('personal')">
                        <!-- <div class="sex"><img src="../../assets/images/male.png"></div> -->
                    </div>
                    <div class="modular-box01">
                        <div class="user-name01">
                            <h5>{{userName || tempName}}</h5>
                            <div v-if="!!userName" class="authen-tag authen-tag-white">
                                <img src="../../assets/images/icon-ysm.png">
                                <span>已實名</span>
                            </div>
                        </div>
                        <div class="phone-num01">{{realNameInfo.telephone}}</div>
                    </div>
                </a>
                <div @click="toSetting" class="setting-container">
                    <img :src="settingSrc" alt="">
                </div>
            </div>
            <div class="m-tab02">
                <a v-for="tab in tabList" :key="tab.type" @click="linkGo(tab.type)">
                    <img class="tab-icon02" :src="tab.imgSrc">
                    <span>{{tab.text}}</span>
                </a>
            </div>
<!--            <div class="m-modular02">-->
<!--                <div class="list-top-con01 clearfix">-->
<!--                    <h5>我的服務</h5>-->
<!--                </div>-->
<!--                <ul class="m-tab01 clearfix">-->
<!--                    <li v-for="icon in mineServiceList" :key="icon.text">-->
<!--                        <a @click="toHref(icon.id)">-->
<!--                            <img :src="icon.iconSrc" class="tab-icon01">-->
<!--                            <span>{{icon.text}}</span>-->
<!--                        </a>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <div class="m-modular02">-->
<!--                <div class="list-top-con01 clearfix">-->
<!--                    <h5>便民服務</h5>-->
<!--                </div>-->
<!--                <ul class="m-tab01 clearfix">-->
<!--                    <li v-for="icon in iconList" :key="icon.text">-->
<!--                        <a @click="toHref(icon.id)">-->
<!--                            <img :src="icon.iconSrc" class="tab-icon01">-->
<!--                            <span>{{icon.text}}</span>-->
<!--                        </a>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
        </div>
        <common-footer :currentTab="currentTab"></common-footer>
    </div>
</template>
<script>
import store from '../../store'
import {mapState} from 'vuex'
// import CommonHeader from '@/components/header'
import commonFooter from '../../components/footer'
export default({
    store,
    data() {
        return {
            nanImgSrc: require('@/assets/images/avadar.png'),
            nvImgSrc: require('@/assets/images/avadar-nv.png'),
            avadarImgSrc: require('@/assets/images/default-avadar.png'),
            userId: null,
            sex: '',
            settingSrc: require('@/assets/images/setting-icon.png'),
            userName: '',
            tempName: '',
            tabList: [
            //     {
            //     type: 'record',
            //     imgSrc: require('@/assets/images/icon-cyjzr.png'),
            //     text: '我的預約'
            // },{
            //     type: 'doctor',
            //     imgSrc: require('@/assets/images/icon-wdys.png'),
            //     text: '我的醫生'
            // },
            //   {
            //     type: 'personal',
            //     imgSrc: require('@/assets/images/icon-dzjkk2.png'),
            //     text: '個人信息'
            // }
            ],
            currentTab: '/mine',
            bannerList: {
                imgSrc: '',
                href: ''
            },
            mineServiceList: [
            //     { // 我的服務
            //     id: 7,
            //     iconSrc: require('@/assets/images/icon-yhq.png'),
            //     text: '我的優惠券',
            //     href: 'http://appweb.dalutone.cn/app/vaccin'
            // }
            ],
            currentPage: '/index',
            iconList: [
            //     { // 便民服務
            //     id: 1,
            //     iconSrc: require('@/assets/images/icon-xgymyy@2x.png'),
            //     text: '新冠疫苗預約',
            //     href: 'http://appweb.dalutone.cn/app/vaccin'
            // }, {
            //     id: 2,
            //     iconSrc: require('@/assets/images/icon-mineyb.png'),
            //     text: '新冠疫情便民',
            //     href: 'https://mztapp.fujian.gov.cn:8190/mztAppWeb/app/ncov2019/newIndex.jsp'
            // }, {
            //     id: 3,
            //     iconSrc: require('@/assets/images/icon-jwlmjkm@2x.png'),
            //     text: '境外入閩健康碼',
            //     href: 'https://mztapp.fujian.gov.cn:8200/fjyqhm/app/bmjwrmjkm/home.jsp'
            // }, {
            //     id: 4,
            //     iconSrc: require('@/assets/images/icon-sbfw@2x.png'),
            //     text: '醫保服務',
            //     href: 'http://220.160.52.192/med/rest/med/loginform.htm'
            // }, {
            //     id: 5,
            //     iconSrc: require('@/assets/images/icon-ybfw@2x.png'),
            //     text: '社保服務',
            //     href: 'http://rst.fujian.gov.cn/m/#!/'
            // }
            ]
        }
    },
    computed: {
        ...mapState({
            storeFuTitle: state => state.changeStoreFuTitle.storeFuTitle,
            realNameInfo: state => state.layout.realNameInfo
        })
    },
    mounted() {
        this.$store.dispatch('getRealNameInfo').then(res => {
            console.log('realNameInfo,', res)
            this.userId = res.data['id']
            this.userName = res.data['name']
            this.sex = res.data['sex']
            // 如果用户名都没有说明是未实名
            if(this.userName) {
                if(this.sex == 1) {
                    console.log('nan')
                    this.avadarImgSrc = this.nanImgSrc
                }else if(this.sex == 2) {
                    console.log('nv')
                    this.avadarImgSrc = this.nvImgSrc
                }
            }
        })
      this.tempName = `hx_tyt${parseInt(Math.random() * 1000)}`
        // new Swiper ('.swiper-container', {
        //     pagination: '.swiper-pagination',
        // })
    },
    methods: {
        toHref(id) {
            if(id == 7){
                this.$router.push('/mineCard')
                return;
            }
            if(id === 4 || id === 5) {
                this.$router.push(`/noOpen?title=${this.iconList[id - 1]['text']}`)
            }else {
                this.$router.push(`/mineContainer?id=${id}`)
            }
        },
        handleClick () {
            this.$weui.toast('操作成功', 3000)
        },
        handleTabToggle(tag) {
            if(`/tag` === this.currentPage) return;
            this.currentPage = `/${tag}`
            // 路由跳转
            this.$router.push(this.currentPage)
        },
        linkGo(target) {
            switch(target) {
                // case 'doctor':
                //     // this.$router.push(`/container?from=doctor&id=${this.userId}`)
                //     window.location.href="https://wxauth.yihu.com/apiweb/tp.html?point=wdys4grzx&thirdPartyUserId=" + this.userId + "&appId=9001217&sourceType=2"
                //     break;
                case 'personal':
                    this.$router.push('/mineInfo')
                    break;
                // case 'record':
                //     // this.$router.push(`/container?from=record&id=${this.userId}`)
                //     window.location.href="https://wxauth.yihu.com/apiweb/tp.html?point=ghjl&thirdPartyUserId=" + this.userId + "&appId=9001217&sourceType=2"
                //     break;
                default:
                    break;
            }
        },
        toSetting() {
            this.$router.push('/setting')
        }
    },
    components: {
        commonFooter
      // ,
      //   CommonHeader
    }
})
</script>
<style lang="scss" scoped>
.m-wrapper01 {
    padding-bottom: 50px;
    min-height: calc(100vh - 44px);
    background: #fff;
}

.m-modular02  {
    .list-top-con01  {
        h5 {
            padding-left: 20px;
        }
    }
}

.container {
    padding-top: 0px;
}

.m-modular01 {
    width: 100%;
    background: url(../../assets/images/bg-1.jpg) center top no-repeat;
    background-size: cover;
    padding: 34px 24px 76px;
    box-sizing: border-box;
}

.m-avatar01 {
    margin-right: 12px;
}
.user-name01 h5 {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    float: left;
}
.authen-tag {
    float: left;
    padding: 0 7px 0 5px;
    height: 20px;
    color: #fff;
    font-size: 12px;
    margin-left: 9px;
}
.authen-tag-white {
    background: #fff;
    border-radius: 10px;
    color: #02BCBD;
}
.authen-tag img {
    float: left;
    width: 12px;
    height: 12px;
    margin: 4px 3px 0 0;
}
.phone-num01 {
    line-height: 18px;
    margin-top: 13px;
    color: #fff;
    font-size: 14px;
    text-align: left;
}
.m-tab01 li{
    width: 33.3333%;
    float: left;
    margin-top: 20px;
}

.setting-container {
    position: absolute;
    right: 24px;
    top: 68px;
    width: 24px;
    height: 24px;
    img {
        width: 100%;
        height: 100%;
    }
}
</style>
